import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { H4 } from '../../styleElements'

const Product = ({ image, name, url = '', ...rest }) => {
  return (
    <Root href={url} target="_blank" {...rest}>
      <ProductImgWrapper>
        <ProductImg fluid={image} objectFit="cover" objectPosition="50% 50%" />
      </ProductImgWrapper>
      <Name>{name}</Name>
    </Root>
  )
}

const Root = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
`

const ProductImgWrapper = styled.div`
  width: 60%;
`

const ProductImg = styled(Img)`
  margin-bottom: 10px;
  width: 100%;

  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 24%;
  border: none;
`

const Name = styled(H4)`
  text-align: center;
`

export default Product
